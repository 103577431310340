import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, notification, Select } from 'antd';
import styled from 'styled-components';
import { Option } from 'antd/es/mentions';
import { device } from '../../../utils/device';
import { createAvatar } from '../../../services/Avatar';
import { createSlider } from '../../../services/Slider';

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	margin-top: 20px;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;

	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const CreateSliderForm = () => {
	const [loading, setLoading] = useState();
	const [subjectTypeValue, setSubjectTypeValue] = useState();
	const [typeValue, setTypeValue] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [image, setImage] = useState();
	const [getSliderLang, setSliderLang] = useState();

	const onFinish = values => {
		setLoading(true);
		const formData = new FormData();
		formData.append('type', typeValue);
		formData.append('subjectType', subjectTypeValue);
		formData.append('startDate', startDate);
		formData.append('endDate', endDate);
		formData.append('language', getSliderLang);
		formData.append('image', image, image.name);

		if (values.link) {
			formData.append('link', values.link);
		}

		if (values.subject) {
			formData.append('subject', values.subject);
		}

		createSlider(formData)
			.then(res => {
				setLoading(false);
				console.log('res', res);
				window.location = '/sliders';
			})
			.catch(err => {
				setLoading(false);
				console.log(err);
				notification.error({
					message: err.message,
				});
			});
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const fileSelectedHandler = event => {
		console.log('files: ', event.target.files[0]);
		setImage(event.target.files[0]);
	};

	const subjectTypeValueOnChange = event => {
		console.log('type: ', event);
		setSubjectTypeValue(event);
	};

	const typeValueOnChange = event => {
		console.log('type:', event);
		setTypeValue(event);
	};

	const startDateOnChange = (date, dateString) => {
		console.log('asd: ', dateString);
		setStartDate(dateString);
	};

	const endDateOnChange = (date, dateString) => {
		setEndDate(dateString);
	};

	return (
		<>
			<FormContainer>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="language"
						label="Slider Dili"
						rules={[
							{
								required: true,
								message: 'Bir dil secin.',
							},
						]}>
						<Select
							placeholder="Dil secin"
							value={getSliderLang}
							onChange={lang => setSliderLang(lang)}>
							<Option value={'tr'}>Turkce</Option>
							<Option value={'en'}>English</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="type"
						label="Tip"
						rules={[
							{
								required: true,
								message: 'Lütfen tip seçin.',
							},
						]}>
						<Select value={typeValue} onChange={typeValueOnChange}>
							<Option value={'mobile'}>Mobile</Option>
							<Option value={'web'}>Web</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="subjectType"
						label="Yönlendirme Tipi"
						rules={[
							{
								required: true,
								message: 'Lütfen yönlendirme tipi seçin.',
							},
						]}>
						<Select
							value={subjectTypeValue}
							onChange={subjectTypeValueOnChange}>
							<Option value={'user'}>Kullanıcı [user]</Option>
							<Option value={'work'}>Eser [work]</Option>
							<Option value={'episode'}>Bölüm [episode]</Option>
							<Option value={'home-list'}>
								Anasayfa Listesi [home-list]
							</Option>
							<Option value={'post'}>Post [post]</Option>
							<Option value={'coin'}>Token Sayfası [coin]</Option>
							<Option value={'subscription'}>
								Abonelik Sayfası [subscription]
							</Option>
							<Option value={'none'}>Yok [none]</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="subject"
						label="Yönlendirilecek ID"
						rules={[
							{
								required: false,
								message: 'Lütfen id girin.',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="startDate"
						label="Başlangıç Tarihi"
						rules={[
							{
								required: true,
								message: 'Lütfen başlangıç tarihi seçin.',
							},
						]}>
						<DatePicker
							onChange={startDateOnChange}
							style={{ width: '100%' }}
						/>
					</Form.Item>

					<Form.Item
						name="endDate"
						label="Bitiş Tarihi"
						rules={[
							{
								required: true,
								message: 'Lütfen bitiş tarihi seçin.',
							},
						]}>
						<DatePicker
							onChange={endDateOnChange}
							style={{ width: '100%' }}
						/>
					</Form.Item>

					<Form.Item
						name="link"
						label="Link"
						rules={[
							{
								required: false,
								message: 'Lütfen link girin.',
							},
						]}>
						<Input />
					</Form.Item>

					<input
						required
						style={{ width: '100%', marginTop: '30px' }}
						type="file"
						onChange={fileSelectedHandler}
						className="custom-file-input"
					/>

					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '180px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Oluştur
								</Button>
							</PrimaryButton>
							<DefaultButton>
								<Button
									style={{ width: '180px' }}
									type="default"
									href={'/sliders'}
									htmlType="submit">
									Geri
								</Button>
							</DefaultButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</FormContainer>
		</>
	);
};
export default CreateSliderForm;
