import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, notification, Select } from 'antd';
import styled from 'styled-components';
import { Option } from 'antd/es/mentions';
import { device } from '../../../utils/device';
import { getSliderById, updateSlider } from '../../../services/Slider';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const FormContainer = styled.div`
	width: 50%;

	@media ${device.mobileL} {
		width: 100%;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	margin-top: 20px;

	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const DefaultButton = styled.div`
	margin-left: 10px;

	@media ${device.mobileL} {
		margin-left: 0px;
		margin-top: 10px;
	}
`;

/**
 *
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const EditSliderForm = ({ id }) => {
	const [loading, setLoading] = useState(false);
	const [typeValue, setTypeValue] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [image, setImage] = useState();

	const [getSliderDetails, setSliderDetails] = useState([]);
	const [isDetailsLoading, setIsDetailsLoading] = useState(true);
	const { id: sliderId } = useParams();

	const [getSliderLang, setSliderLang] = useState();
	const onFinish = values => {
		setLoading(true);
		const formData = new FormData();
		if (typeValue) formData.append('subjectType', typeValue);
		if (startDate) formData.append('startDate', startDate);
		if (endDate) formData.append('endDate', endDate);
		if (getSliderLang) formData.append('language', getSliderLang);
		if (image) formData.append('image', image, image.name);

		if (values.subject) {
			formData.append('subject', values.subject);
		}

		updateSlider(formData, id)
			.then(res => {
				setLoading(false);
				console.log('res', res);
				window.location = '/sliders';
			})
			.catch(err => {
				setLoading(false);
				console.log(err);
				notification.error({
					message: err.message,
				});
			});
	};

	useEffect(() => {
		if (sliderId) {
			fetchSliderIdFromServer();
		}
	}, [sliderId]);

	const fetchSliderIdFromServer = async () => {
		const sliderDetails = await getSliderById(sliderId);
		setIsDetailsLoading(false);

		setSliderDetails(sliderDetails?.data);
		console.log('Slider Details', sliderDetails);
	};
	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const fileSelectedHandler = event => {
		setImage(event.target.files[0]);
	};

	const typeValueOnChange = event => {
		console.log('type: ', event);
		setTypeValue(event);
	};

	const startDateOnChange = (date, dateString) => {
		console.log('asd: ', dateString);
		setStartDate(dateString);
	};

	const endDateOnChange = (date, dateString) => {
		console.log(date, dateString);
		setEndDate(dateString);
	};

	return (
		<>
			<FormContainer>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="language"
						label="Slider Dili"
						rules={[
							{
								required: true,
								message: 'Bir dil secin.',
							},
						]}>
						<Select
							placeholder="Dil secin"
							value={getSliderLang}
							onChange={lang => setSliderLang(lang)}>
							<Option value={'tr'} selected>
								Turkce
							</Option>
							<Option value={'en'}>English</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="subjectType"
						label="Yönlendirme Tipi"
						rules={[
							{
								required: false,
								message: 'Lütfen yönlendirme tipi seçin.',
							},
						]}>
						<Select value={typeValue} onChange={typeValueOnChange}>
							<Option value={'user'}>Kullanıcı [user]</Option>
							<Option value={'work'}>Eser [work]</Option>
							<Option value={'episode'}>Bölüm [episode]</Option>
							<Option value={'home-list'}>
								Anasayfa Listesi [home-list]
							</Option>
							<Option value={'post'}>Post [post]</Option>
							<Option value={'coin'}>Token Sayfası [coin]</Option>
							<Option value={'subscription'}>
								Abonelik Sayfası [subscription]
							</Option>
							<Option value={'none'}>Yok [none]</Option>
						</Select>
					</Form.Item>
					<Form.Item
						name="subject"
						label="Yönlendirilecek ID"
						rules={[
							{
								required: false,
								message: 'Lütfen id girin.',
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="startDate"
						label="Başlangıç Tarihi"
						rules={[
							{
								required: false,
								message: 'Lütfen başlangıç tarihi seçin.',
							},
						]}>
						<DatePicker
							onChange={startDateOnChange}
							style={{ width: '100%' }}
						/>
					</Form.Item>

					<Form.Item
						name="endDate"
						label="Bitiş Tarihi"
						rules={[
							{
								required: false,
								message: 'Lütfen bitiş tarihi seçin.',
							},
						]}>
						<DatePicker
							onChange={endDateOnChange}
							style={{ width: '100%' }}
						/>
					</Form.Item>

					<input
						// required
						style={{ width: '100%', marginTop: '30px' }}
						type="file"
						onChange={fileSelectedHandler}
						className="custom-file-input"
					/>

					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '180px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Güncelle
								</Button>
							</PrimaryButton>
							<DefaultButton>
								<Button
									style={{ width: '180px' }}
									type="default"
									href={'/sliders'}
									htmlType="submit">
									Geri
								</Button>
							</DefaultButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</FormContainer>
		</>
	);
};
export default EditSliderForm;
