import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { device } from '../../../../utils/device';
import { useTranslation } from 'react-i18next';
const backgroundURL = '/img/others/img-17.jpg';

/**
 *
 * @type {{backgroundImage: string, backgroundSize: string, backgroundRepeat: string}}
 */
const backgroundStyle = {
	backgroundImage: `url(${backgroundURL})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
};

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	height: 100vh;

	@media ${device.ipadL} {
		display: none;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const LoginInfoCard = () => {
	const { t } = useTranslation();
	return (
		<>
			<Col xs={0} sm={0} md={0} lg={8}>
				<Container>
					<div
						className="d-flex flex-column justify-content-between h-100 px-4"
						style={backgroundStyle}>
						<Row justify="center" style={{ marginTop: '230px' }}>
							<Col xs={0} sm={0} md={0} lg={20}>
								<img
									className="img-fluid mt-5 mb-5"
									src="/img/others/img-18.png"
									alt=""
								/>
								<h1 className="text-white">Hoş geldiniz</h1>
								<p className="text-white">
									Çizgi App - Admin Panel
								</p>
							</Col>
						</Row>
					</div>
				</Container>
			</Col>
		</>
	);
};
export default LoginInfoCard;
