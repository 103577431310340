import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Table, Tooltip, Switch, message } from 'antd';
import { instanceApi } from 'services/Base/base.instance.services';
import { EyeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Layout from 'components/common/Layout';
import pick from 'utils/pick';

const WorkDetailPage = ({ match }) => {
	const id = match.params.id;
	const [work, setWork] = useState(null);
	const [episodes, setEpisodes] = useState([]);
	const history = useHistory();
	const [isUpdating, setIsUpdating] = useState(false);

	useEffect(() => {
		fetchData();
	}, [id]);

	const fetchData = async () => {
		if (!id) return;
		const { data } = await instanceApi().get(`/works/${id}`);

		if (data) {
			setWork(data);
		}

		const { data: episodes } = await instanceApi().get(
			`/works/${id}/episodes?work=${id}&limit=999`
		);

		if (episodes?.results) {
			setEpisodes(episodes.results);
		}
	};

	const columns = [
		{
			title: `Başlık`,
			dataIndex: ['title', 'tr'],
		},
		{
			title: 'Yayında mı?',
			dataIndex: 'isActive',
			render: (_, elm) => {
				return <div>{elm?.isActive ? 'Yayında' : 'Taslak'}</div>;
			},
		},
		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Görüntüle'}>
						<Button
							type="primary"
							className="mr-2"
							icon={<EyeOutlined />}
							onClick={() => {
								history.push(`/works/${id}/episodes/${elm.id}`);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	const onUpdate = async () => {
		try {
			const body = pick(work, ['highlight']);
			setIsUpdating(true);
			const { data } = await instanceApi().patch(
				`/works/${id}/manage`,
				body
			);
			message.success('Başarıyla kaydedildi.');
		} catch (error) {
			message.error(error);
		} finally {
			setIsUpdating(false);
		}
	};

	return (
		<Layout>
			<div>
				<div>
					<h6 className="text-muted text-uppercase mb-3">
						Genel Bilgiler
					</h6>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Id:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{id}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Başlık:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.title?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Giriş:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.introduction?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Slug:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.slug?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Yayın Tipi:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.publicationType}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Durumu:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.isActive === true ? 'Aktif' : 'Pasif'}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Okunma Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.readingCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Yorum Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.commentCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Beğeni Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.likesCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Toplam Değerlendirme:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.totalRating}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Toplam Değerlendirme Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.totalRatingCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kategori Başlık:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.category?.title?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Yayınlanma Tarihi:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.publishDate}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kitap Tipi:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.workType}
						</span>
					</p>
					<Divider />
					<h6 className="text-muted text-uppercase mb-3">
						Oluşturan Kişi Bilgileri
					</h6>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							İsim Soyisim:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.creator?.fullname}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kullanıcı Adı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.creator?.username}
						</span>
					</p>
					<Divider />
					<h6 className="text-muted text-uppercase mb-3">
						Tarih Bilgisi
					</h6>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Tarih:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.createdAt}
						</span>
					</p>
					<Divider />
					<Form
						className="components-table-demo-control-bar"
						style={{ marginBottom: 16 }}>
						<Form.Item label="Öne Çıkar">
							<Switch
								checked={work?.highlight}
								onChange={val =>
									setWork({ ...work, highlight: val })
								}
							/>
						</Form.Item>
						<Button
							type="primary"
							htmlType="button"
							loading={isUpdating}
							disabled={isUpdating}
							onClick={onUpdate}>
							Kaydet
						</Button>
					</Form>
					<Divider />
					{episodes.length > 0 && (
						<div>
							<h6 className="text-muted text-uppercase mb-3">
								Bölümler
							</h6>
							<Table
								columns={columns}
								dataSource={episodes}
								pagination={false}
							/>
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default WorkDetailPage;
